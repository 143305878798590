import React, { Fragment, useState, useEffect, useReducer } from "react";
import { NavLink } from "react-router-dom";
import { Row, Col, Button, Card, Table, Modal } from "react-bootstrap";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../layouts/ScrollToTop";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./customCss/xclx.css";
import {
  notifyDateTime,
  notifyEarlyDateSelection,
  notifyNameWarning,
  notifyDateWarning,
} from "../../notifyHooks/notifyEvents";

import useEvents from "../../../hooks/useEvents";

const initialState = {
  editEventModal: false,
  selectedEvent: null,
};

const reducer = (state, active) => {
  switch (active.type) {
    case "editEventModal":
      return {
        ...state,
        editEventModal: !state.editEventModal,
        selectedEvent: active.payload,
      };
    case "newEventModal":
      return {
        ...state,
        newEventModal: !state.newEventModal,
      };

    default:
      return state;
  }
};

const Events = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState();
  const [editedEventData, setEditedEventData] = useState();
  const [loadState, setLoadState] = useState(false);
  const [name, setEventName] = useState("");
  const [date, setDate] = useState("");
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const navigate = useNavigate();
  const {
    deleteEventHandler,
    createEventHandler,
    updateEventHandler,
    fetchEventById,
    fetchAllEvents,
  } = useEvents();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setLoadState(true);
        const response = await fetchAllEvents();
        if (response.events) {
          const sortedEvents = response.events.sort(
            (a, b) => b.dateTime - a.dateTime
          );

          setEvents(sortedEvents);
        }

        if (response.message === "No events found") {
          swal({
            title: "No events found!",
            text: "Please add a new event!",
            icon: "info",
            buttons: {
              later: {
                text: "Later",
                value: "later",
                className: "btn-later",
              },
              addNewEvent: {
                text: "Add New Event",
                value: "addNewEvent",
                className: "btn-addNew",
              },
            },
            closeOnClickOutside: false,
          }).then((value) => {
            if (value === "later") {
              navigate(`/dashboard`);
            } else if (value === "addNewEvent") {
              navigate(`/events/new`);
            }
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          swal({
            title: "No events found!",
            text: "Please add a new event!",
            icon: "info",
            buttons: {
              later: {
                text: "Later",
                value: "later",
                className: "btn-later",
              },
              addNewEvent: {
                text: "Add New Event",
                value: "addNewEvent",
                className: "btn-addNew",
              },
            },
            closeOnClickOutside: false,
          }).then((value) => {
            if (value === "later") {
              navigate(`/dashboard`);
            } else if (value === "addNewEvent") {
              dispatch({ type: "newEventModal" });
            }
          });
        }
        console.error(error);
      } finally {
        setLoadState(false);
      }
    };

    fetchEvents();
  }, []);

  function normalDate(unixTime) {
    const timestampInMilliseconds = unixTime * 1000;
    const dateTime = new Date(timestampInMilliseconds);
    const day = dateTime.getDate().toString().padStart(2, "0");
    const month = (dateTime.getMonth() + 1).toString().padStart(2, "0");
    const year = dateTime.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  }

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const getCurrentDateTimeEvent = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const transformDate = (date) => {
    const timestampInMilliseconds = new Date(date).getTime();
    const timestampInSeconds = Math.floor(timestampInMilliseconds / 1000);
    return timestampInSeconds;
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    const selectedDate = e.target.value;
    if (selectedDate < getCurrentDateTimeEvent()) {
      notifyEarlyDateSelection();
    }
    setEditedEventData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleNameChange = (e) => {
    const { name, value } = e.target;

    setEditedEventData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEventNameChange = (e) => {
    setEventName(e.target.value);
  };

  const handleEventDateChange = (e) => {
    const selectedDate = e.target.value;
    if (selectedDate < getCurrentDateTimeEvent()) {
      notifyDateTime();
      return;
    }

    setDate(selectedDate);
  };

  const handleEditEvent = async (eventId) => {
    try {
      const response = await fetchEventById(eventId);
      const selectedEventData = response.event;

      const formatEditDate = (date) => {
        const timestampInMilliseconds = date * 1000;
        const dateTime = new Date(timestampInMilliseconds);

        const year = dateTime.getFullYear();
        const month = (dateTime.getMonth() + 1).toString().padStart(2, "0");
        const day = dateTime.getDate().toString().padStart(2, "0");

        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
      };

      // Set initial state for the date input field
      setEditedEventData({
        ...selectedEventData,
        date: formatEditDate(selectedEventData.dateTime),
        eventId,
      });

      dispatch({ type: "editEventModal", payload: response });
      setSelectedEvent(selectedEventData);
    } catch (error) {
      console.error("Failed to fetch event data for editing", error);
    }
  };

  const handleDeleteEvent = async (eventId) => {
    try {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this event!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        className: "text-center-swal",
      }).then(async (willDelete) => {
        if (willDelete) {
          await deleteEventHandler(eventId);
          setEvents((prevEvents) =>
            prevEvents.filter((event) => event._id !== eventId)
          );
          swal("Event has been deleted!", {
            icon: "success",
          });
        } else {
          swal("Event is still active!");
        }
      });
    } catch (error) {
      console.error("Failed to delete event!", error);
    }
  };

  const handleSaveChanges = async () => {
    setButtonIsLoading(true);
    try {
      // Prepare the edited data to be sent to the backend
      const editedData = {
        ...editedEventData,
        dateTime: transformDate(editedEventData.date),
      };

      await updateEventHandler(selectedEvent._id, editedData);

      setEvents((prevEvents) =>
        prevEvents.map((event) =>
          event._id === selectedEvent._id ? editedData : event
        )
      );

      dispatch({ type: "editEventModal" });
      swal("Event has been updated!", {
        icon: "success",
      });
    } catch (error) {
      console.error("Failed to update event", error);
      swal("Oops", "Failed to update event!", "error");
    } finally {
      setButtonIsLoading(false);
    }
  };

  const handleNewEventModal = async () => {
    dispatch({ type: "newEventModal" });
  };

  const handleCreateEvent = async () => {
    if (name.trim() === "") {
      notifyNameWarning();
      return;
    }

    if (date.trim() === "") {
      notifyDateWarning();
      return;
    }

    try {
      const response = await createEventHandler(name, date);

      setEvents((prevEvents) => [...prevEvents, response]);
      dispatch({ type: "newEventModal" });
      swal("Success!", "Event created successfully!", "success");
    } catch (error) {
      swal("Error", "Failed to create event!", "error");
      console.error("Failed to update splits:", error);
    }

    setEventName("");
    setDate("");
  };

  return (
    <Fragment>
      <ScrollToTop />
      <ToastContainer />
      <div className="container-fluid">
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Header>
                <Card.Title style={{ fontSize: "19px" }}>All Events</Card.Title>
                <Button variant="primary" onClick={handleNewEventModal}>
                  Add New Event
                </Button>
              </Card.Header>
              <Card.Body>
                {loadState && (
                  <div className="text-center">
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>
                )}
                {!loadState ? (
                  <Table responsive>
                    <thead>
                      <tr>
                        <th className="col-1 text-center">
                          <strong>#</strong>
                        </th>
                        <th className="col-3 text-center">
                          <strong>NAME</strong>
                        </th>
                        <th className="col-3 text-center">
                          <strong>DATE</strong>
                        </th>
                        <th className="col-3 text-center">
                          <strong>DETAILS</strong>
                        </th>
                        <th className="col-1 text-center">
                          <strong>ACTION</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {events.map((event, index) => (
                        <tr key={index}>
                          <td className="col-1 text-center">
                            <strong>{index + 1}</strong>
                          </td>
                          <td className="col-3 text-center">{event.name}</td>
                          <td className="col-3 text-center">
                            {normalDate(event.dateTime)}
                          </td>
                          <td className="col-3 text-center">
                            {
                              <NavLink to={`/events/${event._id}/races`}>
                                <Button className="btn btn-primary btn-xs btn btn-info light">
                                  View Event
                                </Button>
                              </NavLink>
                            }
                          </td>
                          <td className="col-1 text-center">
                            <Button
                              variant="primary"
                              className="shadow btn-xs sharp me-1"
                              onClick={() => handleEditEvent(event._id)}
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </Button>
                            <Button
                              variant="danger"
                              className="shadow btn-xs sharp"
                              onClick={() => handleDeleteEvent(event._id)}
                            >
                              <i className="fa fa-trash"></i>
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : null}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Modal
          className="fade"
          show={state.editEventModal}
          onHide={() => {
            dispatch({ type: "editEventModal" });
          }}
        >
          <Modal.Header>
            <Modal.Title>Edit Event</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => dispatch({ type: "editEventModal" })}
            ></Button>
          </Modal.Header>
          <Modal.Body>
            {selectedEvent ? (
              <React.Fragment>
                <div className="form-group mb-3 col-md-12">
                  <label className="form-group-text w-25">
                    <b>Event Name</b>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={editedEventData.name}
                    onChange={handleNameChange}
                    name="name"
                  />
                </div>
                <div className="form-group mb-3 col-md-12">
                  <label className="form-group-text w-25">
                    <b>Event Date</b>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    name="date"
                    min={getCurrentDateTime()}
                    value={editedEventData.date}
                    onChange={handleDateChange}
                  />
                </div>
              </React.Fragment>
            ) : (
              <p>No event selected.</p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger light"
              onClick={() => dispatch({ type: "editEventModal" })}
            >
              Close
            </Button>
            <Button
              variant="primary mr-3"
              disabled={buttonIsLoading}
              onClick={() => handleSaveChanges(editedEventData)}
            >
              {buttonIsLoading ? "Saving Changes..." : "Save Changes"}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          className="fade bd-example-modal-lg"
          size="lg"
          show={state.newEventModal}
          onHide={() => {
            dispatch({ type: "newEventModal" });
          }}
        >
          <Modal.Header>
            <Modal.Title>Create New Event</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => dispatch({ type: "newEventModal" })}
            ></Button>
          </Modal.Header>
          <Modal.Body>
            <React.Fragment>
              <div className="form-group">
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      value={name}
                      onChange={handleEventNameChange}
                    />
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>Date</label>
                    <input
                      type="date"
                      className="form-control"
                      min={getCurrentDateTimeEvent()}
                      placeholder="Date"
                      value={date}
                      onChange={handleEventDateChange}
                    />
                  </div>
                </div>
              </div>
            </React.Fragment>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger light"
              onClick={() => dispatch({ type: "newEventModal" })}
            >
              Close
            </Button>
            <Button variant="primary" onClick={() => handleCreateEvent()}>
              Create Event
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Fragment>
  );
};
export default Events;
