import setupCsrfToken from "../csrfUtils";
const retryWithDelay = async (fn, retries = 3, delay = 1000) => {
    for (let i = 0; i < retries; i++) {
      try {
        return await fn();
      } catch (error) {
        if (i === retries - 1) throw error; // Throw error on the last retry
        console.log(`Retrying in ${delay}ms...`);
        await new Promise((resolve) => setTimeout(resolve, delay));
      }
    }
  };
  
  // Use this for fetching CSRF token
  const fetchCsrfTokenWithRetry = () => retryWithDelay(setupCsrfToken, 3, 2000);

  export default fetchCsrfTokenWithRetry;
  