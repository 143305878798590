import axios from "axios";

// Function to fetch and set CSRF token
export const setupCsrfToken = async () => {
  try {
    // Fetch CSRF token from the server
    const response = await axios.get("/csrf-token");
    const csrfToken = response.data?.csrfToken;

    if (!csrfToken) {
      throw new Error("CSRF token is missing in the response.");
    }

    // Set the token in Axios default headers
    axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
    console.log("CSRF token set:", csrfToken);

    return csrfToken;
  } catch (error) {
    console.error("Failed to fetch CSRF token:", error);
    throw error; // Propagate the error if needed
  }
};

export default setupCsrfToken;