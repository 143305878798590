import React, { useState, useEffect, useReducer } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Card, Table, Button, Modal, OverlayTrigger } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import ScrollToTop from "../../layouts/ScrollToTop";
import "./customCss/xclx.css";
import Select from "react-select";

import useSplitTimes from "../../../hooks/useSplitTimes";
import useParticipants from "../../../hooks/useParticipants";
import useRaces from "../../../hooks/useRaces";

const initialState = {
  registerUnknownParticipant: false,
  selectedParticipant: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "registerUnknownParticipant":
      return {
        ...state,
        registerUnknownParticipant: !state.registerUnknownParticipant,
        selectedParticipant: null,
      };
    default:
      return state;
  }
};

const RaceGenerateResults = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const { raceId, eventId } = useParams();
  const [race, setRace] = useState({});
  const [selectedSplits, setSelectedSplits] = useState([]);
  const [invalidSplitTimes, setInvalidSplitTimes] = useState([]);
  const [selectedSplitTimes, setSelectedSplitTimes] = useState([]);
  const [openOptions, setOpenOptions] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [splitTimes, setSplitTimes] = useState([]);
  const [trackersData, setTrackersData] = useState([]);
  const [trackerShow, setTrackerShow] = useState({});
  const [loadState, setLoadState] = useState(false);
  const [firstName, setParticipantFirstName] = useState("");
  const [lastName, setParticipantLastName] = useState("");
  const [email, setParticipantEmail] = useState("");
  const [age, setParticipantAge] = useState("");
  const [gender, setParticipantGender] = useState("");
  const genders = ["Male", "Female"];
  const [unknownParticipantCheck, setUnknownParticipantCheck] = useState([]);
  const [unknownParticipantData, setUnknownParticipantData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    age: "",
    bibNumber: "",
    gender: "",
  });
  const [selectedParticipantsSplits, setSelectedParticipantsSplits] = useState(
    {}
  );

  const { fetchSplitTimesForRaceGenerate, updateParticipantIdHandler } =
    useSplitTimes();
  const { addParticipantHandler } = useParticipants();
  const { fetchRaceByIds, generateConfirmedSplitsHandler } = useRaces();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadState(true);
        const response = await fetchSplitTimesForRaceGenerate(raceId);
        const {
          race,
          trackers,
          splitTimes,
          selectedSplitsB,
          invalidSplitTimes,
        } = response;
        setRace(race);
        setTrackersData(trackers);
        setSplitTimes(splitTimes);
        setSelectedSplits(selectedSplitsB);
        setInvalidSplitTimes(invalidSplitTimes);
        if (splitTimes.length === 0) {
          swal({
            title: "Race has no results yet.",
            icon: "info",
            closeOnClickOutside: false,
            className: "text-center-swal",
            buttons: {
              raceDetails: {
                text: "Race Details",
                value: "raceDetails",
                className: "btn-addNew",
              },
            },
          }).then((value) => {
            if (value === "raceDetails") {
              navigate(`/events/${eventId}/races/${raceId}`);
            }
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoadState(false);
      }
    };

    fetchData();
  }, [raceId, eventId]);

  const handleParticipantFirstNameChange = (e) => {
    setParticipantFirstName(e.target.value);
  };

  const handleParticipantLastNameChange = (e) => {
    setParticipantLastName(e.target.value);
  };

  const handleParticipantEmailChange = (e) => {
    setParticipantEmail(e.target.value);
  };

  const handleParticipantAgeChange = (e) => {
    setParticipantAge(e.target.value);
  };

  const handleParticipantGenderChange = (selectedOption) => {
    setParticipantGender(selectedOption);
  };

  const identifyRedFlag = (splitTime, minTime, startTime) => {
    const difference = splitTime - startTime;

    return difference < minTime;
  };

  const handleTrackerNameToggle = (e, participantId, splitId, time) => {
    setOpenOptions((prevOpenOptions) => {
      const newOpenOptions = {
        ...prevOpenOptions,
        [participantId]: {
          ...prevOpenOptions[participantId],
          [splitId]: {
            ...prevOpenOptions[participantId]?.[splitId],
            [time]: !prevOpenOptions[participantId]?.[splitId]?.[time],
          },
        },
      };

      return newOpenOptions;
    });
  };

  const handleSplitCheckboxChange = (
    participant_id,
    splitId,
    splitTime,
    bib
  ) => {
    setInvalidSplitTimes((prevInvalidSplitTimes) => {
      return prevInvalidSplitTimes.map((invalidSplit) => {
        return {
          ...invalidSplit,
          invalidSplitTime: "",
        };
      });
    });

    setSelectedSplits((prevSelectedSplits) => {
      // Find the split in the prevSelectedSplits array
      const updatedSelectedSplits = prevSelectedSplits.map((split) => {
        // Check if the current split matches the participant_id and splitId
        if (
          split.participantId === participant_id &&
          split.splitId === splitId
        ) {
          // Update the splitTime of the matched split
          return { ...split, SPLITTIME: splitTime };
        }
        return split; // Return the split without modification if not matched
      });

      // Additional calculations for dynamically enabling/disabling options for the next split
      const currentSplitIndex = race.splits.findIndex(
        (split) => split._id === splitId
      );
      const nextSplitIndex = currentSplitIndex + 1;

      if (nextSplitIndex < race.splits.length) {
        const nextSplits = race.splits.slice(nextSplitIndex); // Get all subsequent splits

        const allNextSplitTimes = nextSplits.map((nextSplit) => {
          // Get all split times for each subsequent split
          const splitData = splitTimes.find(
            (split) =>
              split.bibNumber === bib && split.splitId === nextSplit._id
          );
          return splitData ? splitData.splitTime : [];
        });

        const isValidNextSplit = allNextSplitTimes.every((splitTimeArray) => {
          // Check if the selected split time is earlier than all split times for each subsequent split
          return splitTimeArray.every((time) => splitTime < time);
        });

        splitTimes.forEach((split) => {
          if (
            split.participantBib === bib &&
            split.splitId === race.splits[nextSplitIndex]._id
          ) {
            split.disabled = !isValidNextSplit;
          }
        });
      }

      setSelectedSplitTimes({ splitId, splitTime }); // Set selected split time

      return updatedSelectedSplits;
    });
  };

  const handleUnknownParticipantModal = async (participantBib) => {
    dispatch({ type: "registerUnknownParticipant" });
    setUnknownParticipantData({
      ...unknownParticipantData,
      bibNumber: participantBib,
    });
  };

  const handleCreateUnknownParticipant = async () => {
    if (!firstName) {
      toast.info("Please enter first name.");
      return;
    }

    if (!lastName) {
      toast.info("Please enter last name.");
      return;
    }

    if (!gender) {
      toast.info("Please select the gender.");
      return;
    }

    const sameParticipantFound = race.participants.find(
      (participant) => participant.email === email
    );

    if (email && sameParticipantFound && sameParticipantFound.email === email) {
      toast.info("Email already exists");
      return;
    }

    if (age && !/^[0-9]+$/.test(age)) {
      toast.info("Age must be a number");
      return;
    }

    const bib = unknownParticipantData.bibNumber;

    try {
      const response = await addParticipantHandler(raceId, {
        firstName: firstName,
        lastName: lastName,
        email,
        age,
        bib,
        gender: gender.label,
      });
      if (response.status === 200) {
        // Extract the participantId from the response
        const participantId = response.data.participantId;

        // Step 2: Update participantId in SplitTime
        try {
          const updateParticipantIdResponse = await updateParticipantIdHandler({
            participantId: participantId,
            raceId,
            bibNumber: bib,
          });

          if (updateParticipantIdResponse.status === 200) {
            // Fetch updated split times after adding the participant
            const updatedSplitTimesResponse =
              await fetchSplitTimesForRaceGenerate(raceId);
            const {
              race,
              trackers,
              splitTimes,
              inputChecked,
              selectedSplitsB,
            } = updatedSplitTimesResponse;
            const updatedResponse = await fetchRaceByIds(raceId, eventId);
            setSelectedSplits(selectedSplitsB);
            toast.info("Split times are set to default");
            const updatedRace = updatedResponse.race;
            const updatedSplitTimesData = splitTimes;

            // Update the state with the new split times
            setSplitTimes(updatedSplitTimesData);

            // Update the race state
            setRace(updatedRace);
            swal({
              title: "Success!",
              text: "Participant added successfully",
              icon: "success",
              button: "OK",
            });

            dispatch({ type: "registerUnknownParticipant" });
          }
        } catch (error) {
          console.error("Error updating participantId in SplitTime:", error);
          toast.error("Error adding participant's id");
        }
      }
      setUnknownParticipantCheck([]);
    } catch (error) {
      console.error("Error creating participant:", error);
      toast.error("Error adding participant");
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      const response = await generateConfirmedSplitsHandler(
        raceId,
        selectedSplits
      );
      if (unknownParticipantCheck.length > 0) {
        swal({
          title: "Unregistered participants spotted!",
          text: "Please register all participants first, and the proceed with results registration.",
          icon: "warning",
          button: "OK",
          className: "text-center-swal",
        });
        setIsLoading(false);
        return;
      }

      swal({
        title: "Success!",
        text: "Split times registered successfully!",
        icon: "success",
        buttons: {
          addRace: {
            text: "Go to Edit Splits",
            value: "editSplits",
            className: "btn-go-backButton",
          },
          raceDetails: {
            text: "Get back to Race Details",
            value: "raceDetails",
            className: "btn-add-raceButton",
          },
        },
        closeOnClickOutside: false,
      }).then((value) => {
        if (value === "editSplits") {
          navigate(`/events/${eventId}/races/${raceId}/edit-results`);
        } else if (value === "raceDetails") {
          navigate(-1);
        }
      });
      setSelectedSplits(selectedSplits);
    } catch (error) {
      console.error("Error calling the API route:", error);
    } finally {
      setIsLoading(false);
    }
  };

  function normalTime(unixTimestamp) {
    // Create a new Date object by multiplying the timestamp by 1000 (to convert seconds to milliseconds)
    const date = new Date(unixTimestamp * 1000);

    // Options for formatting the time
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false, // Use 24-hour format
    };

    return date.toLocaleTimeString("en-US", options);
  }

  const getPrecedingSplitIndex = (currentSplitId) => {
    const currentIndex = race.splits.findIndex(
      (split) => split._id === currentSplitId
    );
    return currentIndex > 0 ? race.splits[currentIndex - 1]._id : null;
  };

  const identifyValidSplit = (splitTime) => {
    return !invalidSplitTimes.some(
      (invalidSplit) => invalidSplit.invalidSplitTime === splitTime
    );
  };

  return (
    <React.Fragment>
      <ScrollToTop />
      <ToastContainer />
      {loadState && (
        <div className="d-flex align-items-center justify-content-center vh-100">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden"></span>
          </div>
        </div>
      )}
      {splitTimes.length !== 0 && (
        <div className="container-fluid">
          {!loadState ? (
            <React.Fragment>
              {race.participants ? (
                <React.Fragment>
                  <Card>
                    <Card.Header
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Card.Title style={{ fontSize: "19px" }}>
                        <Link to={`/events/${eventId}/races/${raceId}`}>
                          <b>{race.raceName}</b> Race{" "}
                        </Link>
                        &gt; Generate Results
                      </Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th className="text-center col-1">
                              <strong>Participant</strong>
                            </th>
                            <th className="text-center col-2">
                              <strong>Bib</strong>
                            </th>
                            <th className="text-center col-2">
                              <strong>Start</strong>
                            </th>
                            {race.splits.map((raceSplit, splitIndex) => {
                              // Skip the first split when isGunRace is true
                              if (splitIndex > 0 || !race.isGunRace) {
                                return (
                                  <th
                                    className="text-center col-2"
                                    key={raceSplit._id}
                                  >
                                    <strong>{raceSplit.name}</strong>
                                  </th>
                                );
                              }
                              return null;
                            })}
                          </tr>
                        </thead>

                        <tbody>
                          {splitTimes
                            .filter(
                              (splitTimeObject, index, self) =>
                                index ===
                                self.findIndex(
                                  (t) =>
                                    t.bibNumber === splitTimeObject.bibNumber
                                )
                            )
                            .map((splitTimeObject) => {
                              const participant = race.participants.find(
                                (p) => {
                                  // console.log(p, splitTimeObject);
                                  return (
                                    p._id === splitTimeObject.participantId
                                  );
                                }
                              );
                              if (!participant) {
                                unknownParticipantCheck.push(
                                  splitTimeObject.bibNumber
                                );
                              }
                              return (
                                <tr key={splitTimeObject.participantId}>
                                  <td className="text-center col-1">
                                    <strong>
                                      {/* {console.log(participant)} */}
                                      {participant
                                        ? `${participant.firstName} ${participant.lastName}`
                                        : "Unregistered Participant"}
                                    </strong>
                                    <p>
                                      {!splitTimeObject.participantId && (
                                        <Button
                                          className="btn-xxs mt-2"
                                          onClick={() =>
                                            handleUnknownParticipantModal(
                                              splitTimeObject.bibNumber
                                            )
                                          }
                                        >
                                          Add +
                                        </Button>
                                      )}
                                    </p>
                                  </td>
                                  <td className="text-center">
                                    <strong>{splitTimeObject.bibNumber}</strong>
                                  </td>
                                  <td className="text-center col-2">
                                    {race.isGunRace ? (
                                      <label style={{ display: "block" }}>
                                        <input
                                          id={"disablePosition"}
                                          type="radio"
                                          className="form-check-input "
                                          value={normalTime(race.dateTime)}
                                          style={{
                                            margin: "auto",
                                            marginRight: "3px",
                                          }}
                                          defaultChecked={true}
                                        />
                                        {normalTime(race.dateTime)}
                                      </label>
                                    ) : (
                                      ""
                                    )}
                                  </td>

                                  {race.splits.map((raceSplit, splitIndex) => {
                                    // Filter splitTimes for this participant and raceSplit
                                    const splitData = splitTimes.find(
                                      (split) => {
                                        return (
                                          split.bibNumber ===
                                          splitTimeObject.bibNumber &&
                                          split.splitId === raceSplit._id
                                        );
                                      }
                                    );

                                    // Render the split data here
                                    if (splitIndex > 0 || !race.isGunRace) {
                                      return (
                                        <td
                                          className="text-center col-2"
                                          key={raceSplit._id}
                                        >
                                          {splitData ? (
                                            <div>
                                              {splitData.splitTime.map(
                                                (time, index) => {
                                                  // Check if red flag should be displayed
                                                  const shouldDisplayRedFlag =
                                                    identifyRedFlag(
                                                      time,
                                                      raceSplit.minTime,
                                                      race.dateTime
                                                    );

                                                  const isLastSplit =
                                                    time ===
                                                    splitData.splitTime[
                                                    splitData.splitTime
                                                      .length - 1
                                                    ];

                                                  const validSplit =
                                                    identifyValidSplit(time);

                                                  const isBeforeRaceTime =
                                                    time <= race.dateTime;

                                                  // Get the selected time of the preceding split (split 2)
                                                  const precedingSplitIndex =
                                                    getPrecedingSplitIndex(
                                                      raceSplit._id
                                                    );

                                                  const selectedTimeOfPrecedingSplit =
                                                    selectedSplits
                                                      .filter(
                                                        (split) =>
                                                          split.splitId ===
                                                          precedingSplitIndex
                                                      )
                                                      .map(
                                                        (split) =>
                                                          split.splitTime
                                                      )
                                                      .flat();

                                                  const isEnabled = !(
                                                    isBeforeRaceTime ||
                                                    (selectedTimeOfPrecedingSplit &&
                                                      time <=
                                                      selectedTimeOfPrecedingSplit[
                                                      selectedTimeOfPrecedingSplit.length -
                                                      1
                                                      ])
                                                  );

                                                  const lastSplitFromSelectedSplits =
                                                    selectedSplits.find(
                                                      (split) => {
                                                        return (
                                                          split.participantId ===
                                                          splitTimeObject.participantId &&
                                                          split.splitId ===
                                                          raceSplit._id
                                                        );
                                                      }
                                                    );

                                                  const isLastEnabledSplit =
                                                    isEnabled &&
                                                    lastSplitFromSelectedSplits &&
                                                    time ===
                                                    lastSplitFromSelectedSplits.SPLITTIME;

                                                  const isDisabled =
                                                    splitTimes.map((split) => {
                                                      return (
                                                        split.participantId ===
                                                        splitTimeObject.participantId &&
                                                        split.splitId ===
                                                        raceSplit._id
                                                      );
                                                    }).disabled;

                                                  return (
                                                    <div
                                                      key={index}
                                                      style={{
                                                        position: "relative",
                                                        marginBottom: "5px",
                                                      }}
                                                    >
                                                      <label
                                                        style={{
                                                          display: "block",
                                                          color:
                                                            shouldDisplayRedFlag
                                                              ? "red"
                                                              : "",
                                                        }}
                                                      >
                                                        <input
                                                          id={"disablePosition"}
                                                          type="radio"
                                                          className="form-check-input "
                                                          name={`split_${splitTimeObject.participantId}_${raceSplit._id}`}
                                                          value={normalTime(
                                                            time
                                                          )}
                                                          style={{
                                                            margin: "auto",
                                                            marginRight: "3px",
                                                          }}
                                                          onClick={(e) =>
                                                            handleSplitCheckboxChange(
                                                              splitTimeObject.participantId,
                                                              raceSplit._id,
                                                              time,
                                                              splitTimeObject.bibNumber,
                                                              splitData.trackerId,
                                                              e.target.checked
                                                            )
                                                          }
                                                          defaultChecked={
                                                            isLastEnabledSplit
                                                          }
                                                          disabled={
                                                            isBeforeRaceTime ||
                                                            !validSplit ||
                                                            isDisabled ||
                                                            unknownParticipantCheck.length >
                                                            0
                                                          }
                                                        />
                                                        {normalTime(time)}
                                                      </label>

                                                      <span
                                                        date-value={time}
                                                        onClick={(e) => {
                                                          handleTrackerNameToggle(
                                                            e,
                                                            splitTimeObject.participantId,
                                                            raceSplit._id,
                                                            time
                                                          );
                                                          setTrackerShow(
                                                            (
                                                              prevTrackerShow
                                                            ) => ({
                                                              ...prevTrackerShow,
                                                              [`${splitTimeObject.participantId}_${raceSplit._id}_${time}`]:
                                                                !prevTrackerShow[
                                                                `${splitTimeObject.participantId}_${raceSplit._id}_${time}`
                                                                ],
                                                            })
                                                          );
                                                        }}
                                                        style={{
                                                          cursor: "pointer",
                                                          color:
                                                            shouldDisplayRedFlag
                                                              ? "red"
                                                              : "",
                                                          position: "absolute",
                                                          top: "-2px",
                                                          marginLeft: "40px",
                                                          transition:
                                                            "transform 0.5s ease",
                                                          transform:
                                                            openOptions[
                                                              splitTimeObject
                                                                .participantId
                                                            ]?.[
                                                              raceSplit._id
                                                            ]?.[time]
                                                              ? "rotate(360deg)"
                                                              : "rotate(0deg)",
                                                        }}
                                                        className="d-inline-block"
                                                      >
                                                        {openOptions[
                                                          splitTimeObject
                                                            .participantId
                                                        ]?.[raceSplit._id]?.[
                                                          time
                                                        ] ? (
                                                          <FaCaretUp />
                                                        ) : (
                                                          <FaCaretDown />
                                                        )}
                                                      </span>
                                                      <div
                                                        className={`ml-1${trackerShow[
                                                          `${splitTimeObject.participantId}_${raceSplit._id}_${time}`
                                                        ]
                                                          ? " showtracker"
                                                          : " hidetracker"
                                                          }`}
                                                      >
                                                        {openOptions[
                                                          splitTimeObject
                                                            .participantId
                                                        ]?.[raceSplit._id]?.[
                                                          time
                                                        ] && (
                                                            <span className="ml-2">
                                                              {
                                                                trackersData.find(
                                                                  (tracker) =>
                                                                    tracker._id ===
                                                                    splitData.trackerId
                                                                )?.trackerName
                                                              }
                                                            </span>
                                                          )}
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          ) : (
                                            <p>No time recorded</p>
                                          )}
                                        </td>
                                      );
                                    }
                                    return null;
                                  })}
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                      <div className="text-end toolbar toolbar-bottom p-2">
                        <Button
                          className="btn btn-primary"
                          onClick={handleSubmit}
                          disabled={isLoading || selectedSplits.length === 0}
                          style={{
                            cursor:
                              selectedSplits.length === 0 ? "not-allowed" : "",
                            pointerEvents: "all",
                          }}
                        >
                          {isLoading ? "Saving changes..." : "Save changes"}
                        </Button>
                      </div>
                    </Card.Body>
                  </Card>
                </React.Fragment>
              ) : (
                <p>No participants</p>
              )}{" "}
            </React.Fragment>
          ) : null}
        </div>
      )}
      <Modal
        className="fade bd-example-modal-lg"
        size="lg"
        show={state.registerUnknownParticipant}
        onHide={() => {
          dispatch({ type: "registerUnknownParticipant" });
        }}
      >
        <Modal.Header>
          <Modal.Title>
            Register New Participant{" "}
            <strong>(bib: {unknownParticipantData.bibNumber})</strong>
          </Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => dispatch({ type: "registerUnknownParticipant" })}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <React.Fragment>
            <div className="form-group">
              <div className="row">
                <div className="form-group mb-3 col-md-6">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    onChange={handleParticipantFirstNameChange}
                  />
                </div>
                <div className="form-group mb-3 col-md-6">
                  <label>Surname</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Surname"
                    onChange={handleParticipantLastNameChange}
                  />
                </div>
                <div className="form-group mb-3 col-md-6">
                  <label>Email (optional)</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email"
                    onChange={handleParticipantEmailChange}
                  />
                </div>
                <div className="form-group mb-3 col-md-6">
                  <label>Age (optional)</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Age"
                    onChange={handleParticipantAgeChange}
                  />
                </div>
                <div className="form-group mb-3 col-md-6">
                  <label>Bib Number</label>
                  <input
                    type="text"
                    className="form-control"
                    value={unknownParticipantData.bibNumber}
                    readOnly
                  />
                </div>
                <div className="form-group mb-3 col-md-6">
                  <label>Gender</label>
                  <Select
                    style={{
                      lineHeight: "40px",
                      color: "#7e7e7e",
                      paddingLeft: "15px",
                    }}
                    value={gender}
                    onChange={handleParticipantGenderChange}
                    options={genders.map((gender) => ({
                      value: gender,
                      label: gender,
                    }))}
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger light"
            onClick={() => dispatch({ type: "registerUnknownParticipant" })}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => handleCreateUnknownParticipant()}
          >
            Register
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default RaceGenerateResults;
