import React, { useState, useEffect, useReducer } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  Table,
  Modal,
  Accordion,
} from "react-bootstrap";
import swal from "sweetalert";
import { useParams, Link } from "react-router-dom";
import Select from "react-select";
import "./customCss/xclx.css";
import ScrollToTop from "../../layouts/ScrollToTop";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import useRaces from "../../../hooks/useRaces";
import useTrackers from "../../../hooks/useTrackers";
import { notifyNoChanges } from "../../notifyHooks/notifyTrackers";

const initialState = {
  addTrackerModal: false,
  editSplitModal: false,
  selectedRaceForEdit: null, // To store the selected race for editing
};

const reducer = (state, action) => {
  switch (action.type) {
    case "manageSplitModal":
      return {
        ...state,
        manageSplitModal: !state.manageSplitModal,
        selectedRaceForEdit: action.selectedRace, // Store the selected race
      };
    default:
      return state;
  }
};

const TrackerDetails = () => {
  const { trackerId } = useParams();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [trackerRaces, setTrackerRaces] = useState([]);
  const [tracker, setTracker] = useState({});
  const [splitsDB, setSplitsDB] = useState({});
  const [selectedRaceId, setSelectedRaceId] = useState(null);
  const [trackerSplits, setTrackerSplits] = useState([]);
  const [databaseSplits, setDatabaseSplits] = useState([]);
  const [trackers, setTrackers] = useState([]);
  const [loadState, setLoadState] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);

  const { fetchRacesByTrackerId, editSplitsHandler } = useRaces();
  const { fetchAllTrackers, fetchTrackerById } = useTrackers();

  useEffect(() => {
    const fetchTrackers = async () => {
      try {
        setLoadState(true);
        const response = await fetchAllTrackers();
        setTrackers(response.trackers);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadState(false);
      }
    };

    fetchTrackers();

    const fetchTrackerRaces = async () => {
      try {
        const response = await fetchRacesByTrackerId(trackerId);
        setTrackerRaces(response);
        setSplitsDB(response);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchTracker = async () => {
      try {
        const response = await fetchTrackerById(trackerId);
        setTracker(response.tracker);
      } catch (error) {
        console.error(error);
      }
    };

    fetchTracker();
    fetchTrackerRaces();
  }, [trackerId]);

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  const formatDate = (date) => {
    const inputDatetime = new Date(date);
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    const formattedDate = inputDatetime.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  const handleManageSplitRace = async (raceId) => {
    const selectedRaceForEdit = trackerRaces.find(
      (race) => race._id === raceId
    );
    dispatch({ type: "manageSplitModal", selectedRace: selectedRaceForEdit });

    // Set databaseSplits to the initial splits
    setDatabaseSplits(selectedRaceForEdit.splits);

    // Update trackerSplits if needed
    const dbSplits = selectedRaceForEdit.splits.map((split) => ({
      trackerId: split.trackerId,
      splitId: split._id,
      raceId: selectedRaceForEdit._id,
      splitName: split.name,
      // splits: selectedRaceForEdit.splits,
    }));

    setTrackerSplits(dbSplits);
  };

  const handleTrackerRaceSplitChange = (selectedOption, raceId, trackerId) => {
    const inputSplitIdValue = selectedOption.value;
    const inputSplitIdLabel = selectedOption.label;

    // Check if the selected split is already associated with another tracker
    const isSplitAlreadyAssigned = trackerSplits.some(
      (split) =>
        split.trackerId !== trackerId && split.splitId === inputSplitIdValue
    );

    // Create a copy of the trackerSplits object to avoid modifying the original state
    const updatedSplits = [...trackerSplits];

    // Find the index of the split for the selected tracker within the race
    const splitIndex = updatedSplits.findIndex(
      (split) => split.raceId === raceId && split.trackerId === trackerId
    );

    // Update the split information for the selected tracker
    updatedSplits[splitIndex] = {
      trackerId,
      splitId: inputSplitIdValue,
      raceId,
      splitName: inputSplitIdLabel,
    };

    // Now updatedSplits contains the updated split information for the selected tracker
    setTrackerSplits(updatedSplits);
    setSelectedRaceId(raceId);
  };

  // function to get the initial value for the Select component
  const getInitialValue = (trackerId) => {
    const associatedSplit = trackerSplits.find(
      (split) => split.trackerId === trackerId
    );
    return associatedSplit
      ? { value: associatedSplit.splitId, label: associatedSplit.splitName }
      : null;
  };

  const handleSaveChanges = async (raceId, trackerId, updatedSplits) => {
    // Check if there are no changes made
    const isNoChanges = updatedSplits.every((updatedSplit) => {
      const correspondingDatabaseSplit = databaseSplits.find(
        (dbSplit) =>
          dbSplit._id === updatedSplit.splitId &&
          dbSplit.trackerId === updatedSplit.trackerId &&
          dbSplit.name === updatedSplit.splitName
      );
      return !!correspondingDatabaseSplit;
    });

    if (isNoChanges) {
      notifyNoChanges();
      return;
    }
    // Check for duplicate splits
    const splitIds = updatedSplits.map((split) => split.splitId);
    const uniqueSplitIds = new Set(splitIds);

    if (splitIds.length !== uniqueSplitIds.size) {
      swal(
        "Same splits selected.",
        "Please choose different splits per trackers!",
        "warning"
      );

      return;
    }

    try {
      setButtonIsLoading(true);
      // an array to store the split information in the format expected by the backend
      const splitsToUpdate = updatedSplits.map((split) => ({
        trackerId: split.trackerId,
        splitId: split.splitId,
        raceId: split.raceId,
        splitName: split.splitName,
      }));

      const splitData = {
        raceId: raceId,
        splits: splitsToUpdate,
        trackerId: trackerId,
      };

      await editSplitsHandler(trackerId, raceId, splitData);

      setTrackerSplits(splitsToUpdate);

      dispatch({ type: "manageSplitModal" });
      swal("Splits have been updated!", {
        icon: "success",
      });
    } catch (error) {
      console.error("Failed to update splits:", error);
    } finally {
      setButtonIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <ScrollToTop />
      <ToastContainer />
      <div className="container-fluid">
        <Row>
          <Col lg={12}>
            <Accordion
              className="accordion accordion-primary"
              defaultActiveKey="2"
            >
              <Accordion.Item className="accordion-item" key="1" eventKey="2">
                <Accordion.Header
                  className="accordion-header rounded-lg"
                  onClick={toggleAccordion}
                >
                  {isAccordionOpen ? "View Details" : "Hide Details"}
                </Accordion.Header>
                {tracker.trackerName && (
                  <Accordion.Collapse eventKey="2">
                    <div className="accordion-body">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item d-flex px-0 justify-content-between border-bottom">
                          <strong>Full Name</strong>
                          <span className="mb-0">{tracker.fullName}</span>
                        </li>
                        <li className="list-group-item d-flex px-0 justify-content-between border-bottom">
                          <strong>Tracker Name</strong>
                          <span className="mb-0">{tracker.trackerName}</span>
                        </li>
                        <li className="list-group-item d-flex px-0 justify-content-between border-bottom">
                          <strong>Active since</strong>
                          <span className="mb-0">
                            {formatDate(tracker.createdAt)}
                          </span>
                        </li>
                        <li className="list-group-item d-flex px-0 justify-content-between">
                          <strong>Total Races</strong>
                          <span className="mb-0">{trackerRaces.length}</span>
                        </li>
                      </ul>
                    </div>
                  </Accordion.Collapse>
                )}
              </Accordion.Item>
            </Accordion>

            <Card style={{ height: "auto" }}>
              {tracker.trackerName && (
                <Card.Header>
                  <Card.Title style={{ fontSize: "19px" }}>
                    <Link to={`/trackers`}>All Trackers</Link> &gt;{" "}
                    <b>{tracker.trackerName}</b> Races
                  </Card.Title>
                </Card.Header>
              )}

              <Card.Body>
                {loadState && (
                  <div className="text-center">
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>
                )}

                {!loadState ? (
                  <Table responsive>
                    {trackerRaces.length > 0 ? (
                      <thead>
                        <tr>
                          <th className="col-1 text-center">
                            <strong>#</strong>
                          </th>
                          <th className="col-9 text-center">
                            <strong>RACE NAME</strong>
                          </th>
                          <th className="col-5 text-center">
                            <strong>SPLITS</strong>
                          </th>
                        </tr>
                      </thead>
                    ) : (
                      <h5
                        style={{
                          textAlign: "center",
                        }}
                      >
                        No races for this tracker yet.
                      </h5>
                    )}
                    <tbody>
                      {trackerRaces.map((race, index) => (
                        <tr key={race}>
                          <td className="text-center">
                            <strong>{index + 1}</strong>
                          </td>
                          <td className="col-8 text-center">{race.raceName}</td>
                          <td className="col-5 text-center">
                            {
                              <Button
                                className="btn btn-primary btn-xs btn btn-info light"
                                onClick={() => handleManageSplitRace(race._id)}
                              >
                                Manage Splits
                              </Button>
                            }
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : null}
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Modal
          className="fade bd-example-modal-lg"
          size="lg"
          show={state.manageSplitModal}
          onHide={() => {
            dispatch({ type: "manageSplitModal" });
          }}
        >
          <Modal.Header>
            {state.selectedRaceForEdit && (
              <Modal.Title>
                Manage Splits &gt; {state.selectedRaceForEdit.raceName}
              </Modal.Title>
            )}
            <Button
              variant=""
              className="btn-close"
              onClick={() => dispatch({ type: "manageSplitModal" })}
            ></Button>
          </Modal.Header>
          <Modal.Body>
            <React.Fragment>
              <div className="form-group">
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="text-center">
                        <strong>TRACKER NAME</strong>
                      </th>
                      <th className="text-center">
                        <strong style={{ marginRight: "37px" }}>
                          TRACKER SPLIT
                        </strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.selectedRaceForEdit?.trackers?.map(
                      (tracker, index) => (
                        <tr key={index}>
                          <td className="text-center">
                            <strong>
                              {/* {console.log(
                                trackers.find(
                                  (trackerS) => trackerS._id === tracker
                                )?.trackerName
                              )} */}
                              {
                                trackers.find(
                                  (trackerS) => trackerS._id === tracker
                                )?.trackerName
                              }
                            </strong>
                          </td>
                          <td>
                            <div className="d-flex justify-content-center !important">
                              <div
                                className="form-group mb-3 col-md-6"
                                style={{
                                  position: "relative",
                                  right: "10px",
                                  top: "7px",
                                }}
                              >
                                {trackerSplits.length > 0 && (
                                  <Select
                                    style={{
                                      lineHeight: "40px",
                                      color: "#7e7e7e",
                                    }}
                                    value={getInitialValue(tracker)} // Set initial value based on associated split
                                    onChange={(selectedOption) =>
                                      handleTrackerRaceSplitChange(
                                        selectedOption,
                                        state.selectedRaceForEdit?._id,
                                        state.selectedRaceForEdit?.trackers[
                                        index
                                        ]
                                      )
                                    }
                                    options={
                                      databaseSplits.map((split) => ({
                                        value: split._id,
                                        label: split.name,
                                      })) || []
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
              </div>
            </React.Fragment>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger light"
              onClick={() => dispatch({ type: "manageSplitModal" })}
            >
              Close
            </Button>
            <Button
              variant="primary"
              disabled={buttonIsLoading}
              onClick={() =>
                handleSaveChanges(
                  state.selectedRaceForEdit?._id,
                  trackerId,
                  trackerSplits
                )
              }
            >
              {buttonIsLoading ? "Saving changes..." : "Save changes"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default TrackerDetails;
