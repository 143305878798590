import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import { SVGICON } from "../../constant/theme";
import raceLogo from "../../constant/logo-2.png";
import { navtoggle } from "../../../store/actions/AuthActions";

const NavHader = () => {
  const { openMenuToggle } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const sideMenu = useSelector((state) => state.sideMenu);

  const handleToogle = () => {
    dispatch(navtoggle());
  };

  return (
    <div
      className="nav-header"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Link to="/dashboard" className="brand-logo">
        <img
          src={raceLogo}
          alt="Race Logo"
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        />
        {!sideMenu && (
          <i className="d-flex align-items-center">
            <span
              className="ml-2"
              style={{
                fontSize: "1.5rem",
                color: "#dee2e6",
                fontWeight: "bold",
              }}
            >
              Syncrace
            </span>
          </i>
        )}
      </Link>
      <div
        className="nav-control"
        onClick={() => {
          openMenuToggle();
          handleToogle();
        }}
      >
        <div className={`hamburger ${sideMenu ? "is-active" : ""}`}>
          <span className="line">{SVGICON.NavHeaderIcon}</span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
